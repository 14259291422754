export default {
  type: "theme",
  version: 1,
  id: "1",
  default: true,
  config: {
    whitelabel:{
      clientName:"hdfc",
      logo:{
        height: "40px",
        width: "200px",
        // marginLeft:"0px",
      },
      logoImage:"hdfc_logo.png",
      brandColor:"#005AB7",
      urlColor: "#469DDE",      
      common_background_color: "00AE8E",
      festiveTreats:false,
      components:{
        filterModal:{
          textColor: "#262626",
          dropdownColor :"#D0E7FF"
        },
        siteloader_img:"site-loader-adib.png",         
        siteLoader:{
          counterColor:"rgb(109, 192, 237)",
          bgColor:"linear-gradient(170.25deg, #E7F5FC -46.35%, #FFFFFF 47.06%)",
        },
        button:{
          backgroundColor: "#005AB7",  //should be same as primary color of app
          color: "#fff",
        },
        card: {
          arrowColor:"#004388"
        },
        itemsListHeadingColor:"#000811",
        merchant_card:{
          offer_type:{
            backgroundColor: "#D0E7FF",
            color:"#005AB7",
            zIndex:1
          }, //same as merchant_img under merchant_details
          expiry_offer_type:{
            backgroundColor: "#B5B5B5",
            color:"rgb(0, 8, 17,0.5)",
            zIndex:1
          },
          cardCategoryColor: "#727272",
          cardTitleColor: "#000811",
          cardDescColor: "#001122",
          cardLearnMoreColor: "#469dde",
          cardExpire: "#001122",
          detailBg:false,
        }   
      },
      login: {
        loginBackground: "#D0E7F",
        loginLeftLogo: "hdfc_login_popup",
        loginGuest: "#004388",
        loginDropdownShow : false,
      },
      searchpage:{
        searchTitleText: "#000811",
        searchPageMap:"rgba(0, 67, 136, 1)",
      },
      header:{
        navbar: {
          categoryColor: {color:"#000811"},
          //categoryColor_active:{color: "#00337F",borderBottom: "2px solid #FF0000"},
          categoryColor_mbl: { color: "#000811" },
          categorybgColor_mbl: "#005AB7",
          category_mbl_activeColor:"#fff",
          categoryColorActive: "#005AB7",
        },
        Banner: {
          color:"#004388"
        },
        bg: "#004B92",
        lineColor: "#0071E6",
        locationText: "#fff",
        secondoryImage: true,
      },
      homepage:{
        default_banner_image:"banner-en.png",
        default_banner_text_color:"#333",
        banner_bg_color: "#004388" ,  
      },
      cookiepopup: {
        headingcolor: "#004388",
      },
      merchant_details:{      
        merchant_img:{
          backgroundColor: "#D0E7FF",
          color:"#005AB7",
        },
        merDes:"rgb(227, 6, 19)",
        //merchant_imgBorder: "1px solid #ECECEC",
        merchant_tile:{
          backgroundColor:"#000811",
          color:"#fff",
        },
        merchant_details: {
          backgroundRepeat: 'repeat',
          backgroundSize: 'auto', /* You can use 'cover' or specific dimensions if you need */
          backgroundPosition: 'center',
        },
        offer_cards:{
          offerCardTitleColor:"#44444",
          offerCardInfoColor:"#001122",
          offerCardExpand:"#025296", 
          offerCardMenu:{
            color: "#000811",
          },
          cardofferinfo:"#D0E7FF",
          offerCardMenuActive:{
            color: "#004388",
            borderBottom: "2px solid #005AB7",
          },
          offerCardDescriptionTextColor:"#001122"
        },
        redeem_card:{
          bgColor:"#004388", 
          headText:"#FFFFFF",
          redeem_now:{
            backgroundColor:"#0071E6",
            color:"#FFFFFF"
          },
          offerText:{
            color:"#D0E7FF",
            borderColor:"#2f5186",
            validDate: "#FFFFFF"
          }
        },
        redeem_modal:{
          redeem_button:{
            backgroundColor:"#000811",
            color:"#fff",
          },
          headColor: "#000811",
          capColor: "#727272",
          codeColor: "#004388",
          codeTextColor:"#727272"
        },
        detailBg:false,
      },
      wishlistedpage:{
        nodata_heading: "#000811",
        nodata_text:"#727272",
        nodata_button: "#0071E6"
      },
      profile:{
        titleText:{color:"#000811", freqTitleColor: "#444444"},
        descriptionText:{color:"#616161", stillHaveQuesColor: "#001122"},
        profile_menu:{
          color:' #001122'
        },
        profile_menu_ar:{
          color:'#01347A'
        },
        profile_menu_active:{
          backgroundColor: "#D0E7FF",
          borderLeft: "3px solid #004388",
          color: "#004388"
        },
        profile_menu_act_ar: {
          borderRight: "3px solid #01347A",
          color: "#01347A"
        },
        claimed_offer:{
          claimed_button:{
            color: "#FFFFFF",
            background:"#005AB7"
          },
          card_expire: { color:"#616161"},
          nodata_hea: "#727272"
        },
        redemptions:{
          description_title_text:{color:"#444444"},
          description_title_value:{color:"#025296"},
          table_header:{color:"#025296"},
          table_content:{color:"#444444"},
          table_transid:{color:"#868686"}
        },
        faq:{
          questionColor: "#004388",
           goToHomeColor:"#004388"
        }
      },
      footer:{
        // background:"#004388",
        heading:{color:"#00337F"},
        description:"#444444",
        foottext: "#fff",
        backgroundColor1: "#061831",
      },
      tasteCollection:{
        show: false,
      }
    }
  }
};