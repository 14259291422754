/**
 *
 * Disclaimer: Source code mentioned below is(are) Intellectual Property of
 * Crayon Data Holdings Limited (including its subsidiaries and affiliates).
 * Crayon Data Holdings Limited reserves right to own and control it the way
 * it may deem fit. You must refrain from use, access, read, modify, add or
 * delete, sell or use in any other package or programme pertaining to such
 * source code without explicit prior written approval of
 * Crayon Data Holding Limited. Breach of the same shall attract penalty as
 * applicable.
 *
 */

// Heading Component
import React, { PureComponent } from "react";
import { Popover } from "react-tiny-popover";
import clsx from "clsx";

// Import Component
import Icon from "../Icons";
import Notification from "../Notification";
import CardImage from "../../assets/img/card_image.png";
import SettingsIcon from "../../assets/img/settingIcon.png";
import notificationIcon from "../../assets/img/notificationIcon.png";
import { accessTokenHelper } from "../../helper/token";
import config from "../../service/config";
import userImage from "../../assets/img/userProfileIcon.png";
import userImage1 from "../../assets/img/userProfileIcon1.png";
import { arabicContent, arabicHeaderMenu } from "../../constants/index";
import uiConfig from "../../uiConfig";
import { randomTokenGen } from "../../service";

var MatomoTracker = require("matomo-tracker");
var matomo = new MatomoTracker(config.siteId, config.siteURL);
const trackMatomoclick = (menu) => {
  matomo.track({
    url: window.location.href,
    e_c: "my account",
    e_a: "MENU_CLICK",
    e_n: menu
  });
};
const lang = localStorage.getItem("language");
const authed = () => sessionStorage.getItem("USERID");
var uerIconMatch = {
  maya: userImage,
  hdfc: uiConfig.config.whitelabel.festiveTreats ? userImage : userImage1
};
export default class NewProfile extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isPopoverOpen: false,
      modalOpen: false,
      activeMenu: "not-msg",
      settings: true,
      userSettings: null,
      width: 0,
      language: localStorage.getItem("language") || "EN",
      isloggedin: localStorage.getItem("isLoggedin")
    };
  }

  componentDidMount() {
    this.getProfileInfo();
    this.updateWindowDimensions();
    this.setState({ language: localStorage.getItem("language") || "EN" });
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("storage", this.handleStorageChange);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userData !== this.props.userData) {
      this.getProfileInfo();
    }
    this.getNotificationPopup();
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  getNotificationPopup = () => {
    if (this.props.notificationOpen) {
      this.setState({ modalOpen: true });
    }
  };

  getProfileInfo() {
    const { userData } = this.props;
    const userSettings = { ...(userData && userData.userSettings) };
    this.setState({ userSettings });
  }

  /* onclick function */
  _onClickOutside = () => {
    this.setState({ isPopoverOpen: false });
  };

  _onClickItem = () => {
    const { isPopoverOpen } = this.state;
    this.setState({ isPopoverOpen: !isPopoverOpen });
    this.setState({ isloggedin: localStorage.getItem("isLoggedin") });
  };

  _onClickProfile = () => {
    const { history } = this.props;
    this.props.setActiveItem("myProfile");
    this.setState({ isPopoverOpen: false });
    history.push("/home/profile", "myProfile");
    trackMatomoclick("myProfile");
  };

  _onClickPortfoiio = () => {
    const { history } = this.props;

    sessionStorage.setItem("currPage", "Portfolio");

    this.props.setActiveItem("Portfolio");

    this.setState({ isPopoverOpen: false });

    history.push("/home/profile", "Portfolio");
  };

  _onClickClaimed = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "claimed");
    this.props.setActiveItem("claimed");
    this.setState({ isPopoverOpen: false });
    history.push("/home/profile", "claimed");
    trackMatomoclick("claimed");
  };

  _onClickRedemption = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "redemption");
    this.setState({ isPopoverOpen: false });
    this.props.setActiveItem("redemption");
    history.push("/home/profile", "redemption");
    trackMatomoclick("redemption");
  };

  _onClickAppFeedback = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "appFeedback");
    this.setState({ isPopoverOpen: false });
    this.props.setActiveItem("appFeedback");
    history.push("/home/profile", "appFeedback");
    trackMatomoclick("appFeedback");
  };

  _onClickFAQ = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "faq");
    this.setState({ isPopoverOpen: false });
    this.props.setActiveItem("faq");
    history.push("/home/profile", "faq");
    trackMatomoclick("faq");
  };

  _onClickPrivacyPolicy = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "privacy");
    this.setState({ isPopoverOpen: false });
    this.props.setActiveItem("privacy");
    history.push("/home/profile", "privacy");
    trackMatomoclick("privacy");
  };

  _onClickTermsAndCondition = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "termsAndCondition");
    this.setState({ isPopoverOpen: false });
    this.props.setActiveItem("termsAndCondition");
    history.push("/home/profile", "termsAndCondition");
    trackMatomoclick("termsAndCondition");
  };

  _onClickTasteProfile = () => {
    const { history } = this.props;
    sessionStorage.setItem("currPage", "tasteProfile");
    this.props.setActiveItem("tasteProfile");
    this.setState({ isPopoverOpen: false });
    history.push("/home/profile", "tasteProfile");
    trackMatomoclick("tasteProfile");
  };

  _onClickTasteCollection = () => {
    const { setTcPopup } = this.props;
    this.setState({ isPopoverOpen: false });
    setTcPopup(true);
  };

  onModalOpen = () => {
    this.setState({ modalOpen: true, isPopoverOpen: false });
  };

  onModalClose = () => {
    this.setState({ modalOpen: false });
    this.props.notificationPopup();
  };

  _onChangeTab = (activeMenu) => {
    this.setState({ activeMenu });
  };

  onUpdateIcon = (settings) => {
    this.setState({ settings });
  };

  onLogout = () => {
    const { history } = this.props;
    localStorage.clear();
    sessionStorage.clear();
    accessTokenHelper.del();
    localStorage.setItem("language", "EN");
    history.push("/login");
  };

  onLogoutHDFC = () => {
    //const { history } = this.props;
    // sessionStorage.removeItem('USERID');
    localStorage.setItem("isLoggedin", "false");
    const token = randomTokenGen(16);
    localStorage.setItem("TOKEN", token);
    localStorage.setItem("isLoggedin", "false");
    // localStorage.setItem('TOKEN',token);
    localStorage.setItem("USERID", token);
    localStorage.removeItem("allOffers-wishlist");
    this.setState({ isPopoverOpen: false });
    this.props.updateAuth();
    this.props.closeLogin("submit");
    this.props.pushToHome();
  };
  onLoginHDFC = () => {
    this.props.openLogin();
    this.setState({ isPopoverOpen: false });
  };

  handleToggle = (name, value, ident) => {
    const { updatePersonaldetails } = this.props;
    const userSettings = { ...this.state.userSettings };

    userSettings[ident][name] = value;
    if (updatePersonaldetails) {
      updatePersonaldetails(userSettings);
    }
    this.setState({ userSettings });
  };

  _renderMenuItems(title, onClick, isTop) {
    return (
      <div className="col-md-12 p-0" onClick={onClick}>
        <h5 className={`profile-info mb-16 ${isTop ? "mt-10" : "mt-0"}`}>
          {title}
        </h5>
      </div>
    );
  }

  /* Other Render Functions */
  _renderPopover() {
    return (
      <div
        className={`${
          config.isLoginEnabled === "true" ||
          (config.clientName === "HDFC" && this.props.userAuthed)
            ? "profile-content"
            : "profile-content content-card"
        } ${uiConfig?.config?.whitelabel?.tasteCollection?.show ? "" : " tc"}`}
      >
        {" "}
        <div
          className="col-md-12 notification cursor p-0"
          onClick={this.onModalOpen}
        >
          {/* <div className="col-md-12 p-0 flex-end">
            <Icon name="arrow" width={12} height={12} />
          </div> */}
          {config.isLoginEnabled === "true" && (
            <div className="col-md-12 content">
              <div className="col-md-1 p-0">
                <Icon name="notification" width={21} height={25} />
                {/* <span data-testid="avt-bdg" className="bdg" /> */}
              </div>
              <div className="col-md-9 p-0 ml-20">
                <h5 className="text margin-0">Notifications</h5>
                <p className="info margin-0">
                  You have 9 unread notifications. Click here to view them..
                </p>
              </div>
            </div>
          )}
        </div>
        {config.isLoginEnabled === "true" &&
          this._renderMenuItems("Account Details", this._onClickProfile, true)}
        {config.isLoginEnabled === "true" &&
          this._renderMenuItems(
            "My Taste Profile",
            this._onClickTasteProfile,
            false
          )}
        {this.state.language == "EN"
          ? this._renderMenuItems(
              "Your Vouchers",

              this._onClickPortfoiio,

              false
            )
          : this._renderMenuItems(
              arabicHeaderMenu.claimedOffers,

              this._onClickPortfoiio,

              false
            )}
        {this.state.language == "EN"
          ? this._renderMenuItems("Claimed Offers", this._onClickClaimed, false)
          : this._renderMenuItems(
              arabicHeaderMenu.claimedOffers,
              this._onClickClaimed,
              false
            )}
        {uiConfig?.config?.whitelabel?.tasteCollection?.show &&
          this._renderMenuItems(
            "Taste Preference",
            this._onClickTasteCollection,
            false
          )}
        {/* {
          this.state.language == "EN" ?
          this._renderMenuItems(
            "Redemptions",
            this._onClickRedemption,
            false
          )
          :
          this._renderMenuItems(
            arabicHeaderMenu.redemptions,
            this._onClickRedemption,
            false
          )
        } */}
        {this.state.language == "EN"
          ? this._renderMenuItems(
              "App Feedback",
              this._onClickAppFeedback,
              false
            )
          : this._renderMenuItems(
              arabicHeaderMenu.appFeedback,
              this._onClickAppFeedback,
              false
            )}
        {this.state.language == "EN"
          ? this._renderMenuItems("FAQs", this._onClickFAQ, false)
          : this._renderMenuItems(
              arabicHeaderMenu.faq,
              this._onClickFAQ,
              false
            )}
        {this.state.language == "EN"
          ? this._renderMenuItems(
              "Privacy Policy",
              this._onClickPrivacyPolicy,
              false
            )
          : this._renderMenuItems(
              arabicHeaderMenu.privacyPolicy,
              this._onClickPrivacyPolicy,
              false
            )}
        {this.state.language == "EN"
          ? this._renderMenuItems(
              "Terms & Conditions",
              this._onClickTermsAndCondition,
              false
            )
          : this._renderMenuItems(
              arabicHeaderMenu.termsAndConditions,
              this._onClickTermsAndCondition,
              false
            )}
        {uiConfig.config.whitelabel.login.loginDropdownShow &&
          (this.state.isloggedin === "false"
            ? config.clientName === "HDFC" &&
              this.props.userAuthed &&
              this._renderMenuItems("Login", this.onLoginHDFC, false)
            : config.clientName === "HDFC" &&
              this.props.userAuthed &&
              this._renderMenuItems("Logout", this.onLogoutHDFC, false))}
        {config.isLoginEnabled === "true" && (
          <>
            <div className="col-md-12 line" />

            <div className="col-md-12 p-0" onClick={this.onLogout}>
              <div className="col-md-6 p-0">
                <h5 className="profile-info logout mb-0 mt-16">
                  {this.state.language === "EN"
                    ? `Logout`
                    : arabicHeaderMenu.logout}
                </h5>
              </div>
              <div className="col-md-6 text-right p-0">
                <h5 className="profile-info logout mb-0 mt-16">
                  <Icon
                    fill="#FF0000"
                    width={10}
                    height={10}
                    name="arrowRight"
                  />
                </h5>
              </div>
            </div>
          </>
        )}
        {/* {config.clientName==="HDFC"&& this.props.userAuthed && 
        <>
        <div className="col-md-12 line" />
        
        <div className="col-md-12 p-0" onClick={this.onLogoutHDFC}>
          <div className="col-md-6 p-0">
            <h5 className="profile-info logout mb-0 mt-16">{this.state.language==="EN"?`Logout`:arabicHeaderMenu.logout}</h5>
          </div>
          <div className="col-md-6 text-center p-0">
            <h5 className="profile-info logout mb-0 mt-16">
              <Icon fill="#005AB7" width={10} height={10} name="arrowRight" />
            </h5>
          </div>
        </div>
        </>} */}
      </div>
    );
  }

  _renderProfile() {
    const { userData } = this.props;
    const { language } = this.state;
    return (
      <div
        className="flex user-content cursor p-0 mtp-4"
        onClick={this._onClickItem}
        style={{ zIndex: "1000 !important" }}
      >
        <h5
          className={
            language == "EN"
              ? "margin-0 f-15 username usernameRight text-right"
              : "margin-0 f-15 username usernameLeft text-right"
          }
        >
          {config.isLoginEnabled === "true" ? (
            (userData &&
              userData.personalDetails &&
              userData.personalDetails.name) ||
            ""
          ) : (
            <img
              src={uerIconMatch[uiConfig.config.whitelabel.clientName]}
              className="user-image"
            />
          )}
        </h5>
        {config.isLoginEnabled === "true" ? (
          <span></span>
        ) : (
          <div
            className="_ai-self myAccountText"
            style={{ color: uiConfig.config.whitelabel.header.locationText }}
            onClick={this._onClickItem}
          >
            {language == "EN" ? (
              <span>My Account</span>
            ) : (
              <span>{arabicContent.myaccount}</span>
            )}
            {/* <Icon fill="#000" width={10} height={10} name="arrowDown" /> */}
          </div>
        )}
      </div>
    );
  }
  _loginRender() {
    const { userData } = this.props;
    return (
      <div>
        <button
          style={{ width: "109px", height: "32px" }}
          onClick={this.props.openLogin}
          id="login-hdfc"
        >
          LOGIN
        </button>
      </div>
    );
  }
  _renderMsgItemContent(item) {
    const { activeMenu } = this.state;

    if (activeMenu === "not-msg") {
      return (
        <div className="col-md-12 not-list p-0" key={`${item.id}`}>
          <div className="flex">
            <div className="image-itm-div flex-center">
              <img src={CardImage} alt="card_image" width={60} height={42} />
            </div>
            <div className="col-lft">
              <div className="col-header">{item.title}</div>
              <div className="col-des mt-4">{item.description}</div>
              <div className="col-date mt-4">{item.date}</div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  _renderField(id) {
    const { activeMenu } = this.state;
    const cls = {
      "not-nav": true,
      "not-nav-act": id === activeMenu
    };

    return (
      <div className={`${clsx(cls)} p-0`} onClick={() => this._onChangeTab(id)}>
        <Icon width={28} height={28} name={id} />
      </div>
    );
  }

  _renderItems() {
    return (
      <div className="col-md-12 p-0 not-mnu flex">
        {this._renderField("not-msg")}
        {this._renderField("not-cnt")}
      </div>
    );
  }

  _renderNotificationData() {
    const { notificationItems } = this.props;
    const { settings } = this.state;
    const { userSettings } = this.state;

    if (settings) {
      return (
        <>
          <div className="col-md-12 mt-24 p-0">
            <div className="col-md-12 p-0">
              <div className="col-md-12 p-0 flex">
                <h2 className="margin-0">
                  {lang === "AR"
                    ? arabicHeaderMenu.notificationSettings
                    : `Notification Settings`}
                </h2>
              </div>
            </div>
            <div className="col-md-12 p-0 mt-18 notification-title">
              {lang === "AR"
                ? arabicHeaderMenu.notifTxt
                : `Exciting updates on your deals and offers are populated here`}
            </div>
          </div>
          {this._renderItems()}
          <div className="col-md-12 p-0 mt-12">
            {notificationItems.map((item) => this._renderMsgItemContent(item))}
          </div>
        </>
      );
    }

    return (
      <div className="col-md-12 mt-24 p-0">
        <Notification
          isFull
          userSettings={userSettings}
          handleToggle={this.handleToggle}
        />
      </div>
    );
  }

  _renderNotificationDetails() {
    const minWidth = 768;
    const { settings } = this.state;

    // Removed notification/settings Icon for mobile view
    if (minWidth >= this.state.width) {
      return (
        <div className="col-md-12 p-0 not-modal">
          <div className={`col-md-3 p-0 ${settings ? "main" : "main item"}`}>
            <div className="not-cont col-md-12 p-0">
              <div className="col-md-12 header p-0 flex-end">
                <div
                  className="cancel-icn flex-center cursor"
                  onClick={this.onModalClose}
                >
                  <Icon fill="#000" width={15} height={15} name="cancel" />
                </div>
              </div>
              {this._renderNotificationData()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-md-12 p-0 not-modal">
        <div className={`col-md-3 p-0 ${settings ? "main" : "main item"}`}>
          <div className="not-cont col-md-12 p-0">
            <div className="col-md-12 header p-0 flex-end">
              <div
                className="cursor flex-center"
                onClick={() => this.onUpdateIcon(!settings)}
              >
                {settings ? (
                  <img src={SettingsIcon} width="16" />
                ) : (
                  <img src={notificationIcon} width="15" />
                )}
              </div>
              <div className="border-rht" />
              <div
                className="cancel-icn flex-center cursor"
                onClick={this.onModalClose}
              >
                <Icon fill="#000" width={15} height={15} name="cancel" />
              </div>
            </div>
            {this._renderNotificationData()}
          </div>
        </div>
      </div>
    );
  }

  /* Main Render Functions */
  render() {
    const { isPopoverOpen, modalOpen, language } = this.state;
    const minWidth = 768;

    if (minWidth >= this.state.width) {
      return (
        <div className="pro-cnt-div">
          {modalOpen && this._renderNotificationDetails()}
        </div>
      );
    }

    return (
      <div className="pro-cnt-div">
        <Popover
          containerClassName={[
            uiConfig?.config?.whitelabel?.tasteCollection?.show ? "" : " tc",
            config.isLoginEnabled === "true"
              ? "tiny-popup"
              : "tiny-popup tiny-popup-card",
            language === "AR" ? "arabic-lang" : ""
          ].join(" ")}
          isOpen={isPopoverOpen}
          position={["bottom"]}
          reposition={false}
          content={(props) => this._renderPopover(props)}
          onClickOutside={this._onClickOutside}
        >
          {/* {config.clientName === "HDFC" && localStorage.getItem("USERID") ? this._renderProfile() : this._loginRender()} */}
          {this._renderProfile()}
        </Popover>
        {modalOpen && this._renderNotificationDetails()}
      </div>
    );
  }
}
